<template>
  <div>
    <v-dialog transition="dialog-top-transition" v-model="show" max-width="800px" >

      <v-card>
        <v-card-title class="blue darken-2 white--text mb-4">
          <span>Editar Miembro</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editStaff.name"
                  label="Nombre"
                  outlined dense
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="editStaff.position"
                  :items="positions"
                  label="Cargo"
                  outlined dense
                  required
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="show = false" >
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="modifyStaff()" :disabled="isSaving" :loading="isSaving">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <!-- snack notification -->
    <v-snackbar
      v-model="isSnackbar"
      :color="snackcolor"
      :timeout="2000"
      top
    >
      {{ snacktext }}
    </v-snackbar>

  </div>
</template>

<script>

export default {
  name: "EditStaffDialog",
  props:{
    value: Boolean,
    fetchId: String,
  },
  data() {
    return {
      //state
      isSaving: false,
      isSnackbar: false,
      snackcolor: 'success',
      snacktext: '',
      //data
      positions : ['Jefe Área Artístico Cultural del Departamento Estudiantil', 'Ayudante'],
      //form
      editStaff: {
        name: '',
        position: ''
      }
    }
  },
  computed: {
    show: {
      get() { return this.value },
      set(value) { this.$emit('input', value) }
    },
  },
  watch: {
    show() {
      if(this.show) this.loadStaff()
    }
  },
  methods: {
    async loadStaff() {
      try {

        if(!this.fetchId) throw 'Missing id, cannot load info'

        const response = await this.axios.get(`${process.env.VUE_APP_APIURL}/staff/id/${this.fetchId}`)

        if(response.data.data) {
          this.editStaff = {...this.editStaff, ...response.data.data}
        }

      } catch (e) {
        this.snackcolor = 'error'
        this.snacktext = 'Hubo un problema al cargar el miembro del staff'
        this.isSnackbar = true
        console.error(e)
      }
    },
    async modifyStaff() {
      try {

        if(!this.fetchId) return console.error('Missing id, cannot edit')

        if(!this.editStaff.name || !this.editStaff.position) return this.invalidFormNotification()

        this.isSaving = true

        await this.axios.put(`${process.env.VUE_APP_APIURL}/staff/id/${this.fetchId}`, this.editStaff)

        this.$emit('saved')

        this.snackcolor = 'success'
        this.snacktext = 'Miembro modificado'
        this.isSnackbar = true

        this.show = false

        this.editStaff = {
          name: '',
          position: ''
        }

      } catch (e) {
        this.snackcolor = 'error'
        this.snacktext = 'Hubo un problema al modificar el miembro del staff'
        this.isSnackbar = true
        console.error(e)
      } finally {
        this.isSaving = false
      }
    },
    invalidFormNotification() {
      this.snackcolor = 'error'
      this.snacktext = 'Por favor complete todos los campos'
      this.isSnackbar = true
    },
    closeDialog() { this.show = false }
  }
}
</script>